import React, { useState, forwardRef, useImperativeHandle } from "react"
import Portal from "./portal"
import "../assets/css/modal.css";
import Button from '@material-ui/core/Button';

const Modal = forwardRef((props, ref) => {

  const [display, setDisplay] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
      }
    }
  )

  const handleOpen = () => {
    setDisplay(true);
  }

  const handleClose = () => {
    setDisplay(false);
  }

  if (display) {
    return (
      <Portal>
        <div className="modal-wrapper">
          <div className="modal-backdrop"></div>
          <div className="modal-content">
            {props.children}
            <Button className="custom-submit" color="secondary" variant="contained" type="button" onClick={handleClose}>
              close
             </Button>
            {/* <button onClick={handleClose}>close</button> */}
          </div>
        </div>
      </Portal>
    )
  }

  return null
})

export default Modal