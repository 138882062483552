import React, {useRef} from "react";
import { Formik, Form, Field} from 'formik';
import Button from '@material-ui/core/Button';
import { FormControl } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { RadioGroup, Radio } from "@material-ui/core";
import { FormControlLabel, FormGroup } from "@material-ui/core";
// import Radio from '@mui/material/Radio';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker} from "@mui/lab";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { graphql, useStaticQuery } from "gatsby"; 
import axios from "axios";
import Modal from './modal';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../assets/css/customcookie.css";

const strapiContactUrl = process.env.GATSBY_STRAPI_API_URL + "/"; 
const contactUrl = process.env.API_URL + "/"; 

// ReactModal.setAppElement('#___gatsby');
const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        bigMobile: 350,
        tablet: 650,
        desktop: 900
      }
    }
  });

const validationSchema = yup.object({
    name: yup
        .string('First and last')
        .required('required'),
    phone: yup
        .number('Must be a number'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('required'),
    pickupDate: yup
        .date()
        .nullable()
        .required("Pickup date is required"),
    cookieCount: yup
        .number('numbers only')
        .required('required'),
    eventDate: yup
        .date()
        .nullable(),
    cookieFlavors: yup.array().test({
        name: "cookie_flavors_test",
        exclusive: true,
        message: "Select at least one flavor.",
        test: (value) => value.length > 0,
     }),
     //packageType: yup
    //designLevel: yup.string().oneOf(["green","red","blue"]).required()
});

const CustomCookieForm = () => {

    const data = useStaticQuery(query);
    const modalRef1 = useRef();
    //console.log(modalRef1);
    // var isModalOpen = true; //React.useState(false);

    // function handleModalOpen (){
    //     // console.log('handleModalOpen: ', event);
    //     this.setState({ isModalOpen: true })
    // }
    
    // function handleModalClose (){
    //     // console.log('handleModalOpen: ', event);
    //     isModalOpen = false;
    // }

    function sendRequestEmail(reqData){

        //console.log(reqData);

        const requestInfo = {
            email: reqData.email,
            phone: reqData.phone,
            name: reqData.name,
            eventDate: reqData.eventDate,
            eventType: reqData.eventType,
            pickupDate: reqData.pickupDate,
            cookieCount: reqData.cookieCount,
            cookieFlavors: reqData.cookieFlavors,
            packageType: reqData.packageType,
            colorScheme: reqData.colorScheme,
            cookieDesign: reqData.cookieDesign,
            inspiration: reqData.inspiration
        }

        axios({
            method: "post",
            url: strapiContactUrl + "custom-cookie-requests/",
            data: requestInfo,
            headers: { "Content-Type": "text/json" },
          })
        .then(function (response) {
            //handle success
            console.log("Success: " + response);
            modalRef1.current.openModal();
        })
        .catch(function (response) {
            //handle error
            console.log("Error: " + response);
        });
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            phone: '',
            name: '',
            eventDate: null,
            eventType: '',
            pickupDate: null,
            cookieCount: '',
            cookieFlavors: [],
            packageType: '',
            colorScheme: '',
            cookieDesign: '',
            inspiration: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            sendRequestEmail(values);
        },
    });


    return (
        
        <section>
            {/* <button className="btn" onClick={() => modalRef1.current.openModal()}>Open modal</button> */}
            <Modal ref={modalRef1}>
                <h3>Thank you for your request!</h3>
                <p>An email or an invoice will be sent to the email provided within 48 hours.</p>
            </Modal>  
        <div className="custom-cookie-container">
            <ThemeProvider theme={theme}>
                <Box className="custom-cookie-welcome"                        
                        sx={{
                            width: {
                                mobile: "80%",
                                bigMobile: "80%",
                                tablet: "50%",
                                desktop: "50%"
                            }
                        }}
                    >
                        {data.strapiOrderRequest.BannerMessage &&
                    <label>{data.strapiOrderRequest.BannerMessage}</label>}    
                    <label dangerouslySetInnerHTML={{ __html: data.strapiOrderRequest.WelcomeMessage}}></label>
                </Box>
            </ThemeProvider>
            {/* <div className="custom-cookie-welcome">
                {data.strapiOrderRequest.BannerMessage &&
                    <label>{data.strapiOrderRequest.BannerMessage}</label>
                }    
                <label dangerouslySetInnerHTML={{ __html: data.strapiOrderRequest.WelcomeMessage}}></label>
            </div>             */}
            <form onSubmit={formik.handleSubmit}>
            {/* display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    grid-gap: 0.75rem 1rem;
    gap: 50px;
    justify-self: center; */}

    {/* flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    display: flex; */}
                <ThemeProvider theme={theme}>
                    <Box className="cookie-form"
                        sx={{
                            // backgroundColor: "pink",
                            display: {
                                mobile: "flex",
                                bigMobile: "flex",
                                tablet: "inline-grid", //"repeat(2, 1fr)"
                                desktop: "inline-grid"
                            },
                            flexDirection: {
                                mobile: "column",
                                bigMobile: "column",
                            },
                            justifyContent: {
                                mobile: "center",
                                bigMobile: "center"
                            },
                            alignItems:{
                                mobile: "center",
                                bigMobile: "center"
                            },
                            gridTemplateColumns: {
                                tablet: "repeat(auto-fit, minmax(300px, 1fr))", //"repeat(2, 1fr)"
                                desktop: "repeat(auto-fit, minmax(300px, 1fr))"
                            },
                            gridAutoRows: "minmax(min-content, max-content)",
                            gridGap: "0.75rem 1rem",
                            gap: "50px",
                            justifySelf: "center",
                        }}
                    >
                                
                            
                {/* <div className="cookie-form"> */}
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        id="eventType"
                        name="eventType"
                        label="Type of event"
                        value={formik.values.eventType}
                        onChange={formik.handleChange}
                        error={formik.touched.eventType && Boolean(formik.errors.eventType)}
                        helperText={formik.touched.eventType && formik.errors.eventType}
                    />
                    <TextField
                        id="cookieCount"
                        name="cookieCount"
                        label="Number of cookies"
                        value={formik.values.cookieCount}
                        onChange={formik.handleChange}
                        error={formik.touched.cookieCount && Boolean(formik.errors.cookieCount)}
                        helperText={formik.touched.cookieCount && formik.errors.cookieCount}
                    />
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <DatePicker
                            id="pickupDate"
                            label="Date of pickup"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            clearable
                            value={formik.values.pickupDate}
                            onChange={value => formik.setFieldValue("pickupDate", value)}                        
                            KeyboardButtonProps={{
                            "aria-label": "change date"
                            }}
                            renderInput={(props) => (
                                <TextField {...props} helperText={formik.touched.pickupDate && formik.errors.pickupDate} error={formik.touched.pickupDate && Boolean(formik.errors.pickupDate)} />
                            )}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <DatePicker
                            id="eventDate"
                            label="Date of Event"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            clearable
                            value={formik.values.eventDate}
                            onChange={value => formik.setFieldValue("eventDate", value)}
                            KeyboardButtonProps={{
                            "aria-label": "change date"
                            }}
                            renderInput={(props) => (
                                <TextField {...props} helperText="" />
                            )}
                        />
                    </LocalizationProvider>
                    <div>  
                        <label className="info-label">{data.strapiOrderRequest.ColorSchemeDescription}</label>
                        <TextField
                            id="colorScheme"
                            name="colorScheme"
                            label="Color scheme"
                            value={formik.values.colorScheme}
                            onChange={formik.handleChange}
                            error={formik.touched.colorScheme && Boolean(formik.errors.colorScheme)}
                            helperText={formik.touched.colorScheme && formik.errors.colorScheme}
                        />
                    </div>
                    <div>  
                        <label className="info-label">{data.strapiOrderRequest.DesignLevelDescription}</label>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Package type"
                                // name="packageType"   
                                defaultValue="Basic Package"
                                //onChange={formik.handleChange}                   
                            >
                                {data.strapiPricingPage.cookie_types.map((item) => (
                                    <FormControlLabel onChange={formik.handleChange} name="packageType" key={item.CookieType} value={item.CookieType} control={<Radio />} label={item.CookieType} />                            
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>  
                        <label className="info-label">{data.strapiOrderRequest.CookieFlavorDescription}</label>                  
                        <FormGroup>                        
                            {data.allStrapiCookieFlavors.nodes.map((item) => (
                                <FormControlLabel                                     
                                    key={item.Flavor}
                                    control={
                                    <Checkbox value={item.Flavor} name="cookieFlavors" onChange={formik.handleChange} />
                                    }
                                    label={item.Flavor}
                                />
                            ))}
                            {/* <FormControlLabel
                                control={
                                <Checkbox value="cookieFlavor1" name="cookieFlavors" onChange={formik.handleChange} />
                                }
                                label="cookieFlavor1"
                            /> */}
                            <label className="error-label">{formik.touched.cookieFlavors && formik.errors.cookieFlavors}</label>
                        </FormGroup>
                    </div>                    
                    <div>  
                        <label className="info-label">{data.strapiOrderRequest.CookieDesignDescription}</label>
                        <TextField
                            id="cookieDesign"
                            name="cookieDesign"
                            label="Cookie Design"
                            value={formik.values.cookieDesign}
                            onChange={formik.handleChange}
                            error={formik.touched.cookieDesign && Boolean(formik.errors.cookieDesign)}
                            helperText={formik.touched.cookieDesign && formik.errors.cookieDesign}
                        />
                    </div>
                    <div>  
                        <label className="info-label">{data.strapiOrderRequest.InspirationDescription}</label>
                        <TextField
                            id="inspiration"
                            name="inspiration"
                            label="Inspiration"
                            multiline
                            maxRows={4}
                            value={formik.values.inspiration}
                            onChange={formik.handleChange}
                            error={formik.touched.inspiration && Boolean(formik.errors.inspiration)}
                            helperText={formik.touched.inspiration && formik.errors.inspiration}
                        />
                        {/* <Button
                        variant="contained"
                        component="label"
                        >
                            Inspiration Example
                            <input
                                type="file"
                                hidden
                            />
                        </Button> */}
                    </div>                    
                    <Button className="custom-submit" color="primary" variant="contained" type="submit">
                        Send Request
                    </Button>
                    </Box>
                </ThemeProvider>
                {/* </div>             */}
            </form>                      
        </div>        
        </section>
      );
    };

const query = graphql`
    query {   
        strapiPricingPage {
            cookie_types {
              CookieType
            }
        }
        allStrapiCookieFlavors {
            nodes {
                Flavor
            }
        }
        strapiOrderRequest {
            BannerMessage
            ColorSchemeDescription
            CookieCountDescription
            CookieDesignDescription
            CookieFlavorDescription
            DesignLevelDescription
            InspirationDescription
            WelcomeMessage
          }
    }  
`;

export default CustomCookieForm;


