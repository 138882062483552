import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import CustomCookieForm from "../components/custom-cookie-form";
import "../assets/css/orderrequest.css";

const OrderRequest = () => {

    const data = useStaticQuery(query);

    return (
        <Layout seo={data.strapiHomepage.seo}>
            <div className="uk-section">
                <div className="uk-container uk-container-large">          
                    <div className="orderrequest-container">
                        <CustomCookieForm />
                    </div>
                </div>
            </div>
        </Layout>
    );
};
    
const query = graphql`
    query {   
        strapiHomepage  {
            Banner                  
            WelcomeImage {
                url
            }
            WelcomeMessage        
            hero{
                title
            }
        }
    }  
`;


export default OrderRequest;